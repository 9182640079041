import axios from 'axios';

class ViacepApi {
  constructor() {
    this.api = axios.create({
      baseURL: 'https://viacep.com.br/ws/',
    });
  }

  async get(url, options) {
    return this.api.get(url, options);
  }
}

export default new ViacepApi();
