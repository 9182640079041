import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdPrint } from 'react-icons/md';
import Select from 'react-select';
import { format, isBefore, parseISO, subDays } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';

// import completedOrdersApi from '~/services/completedOrdersApi';
import printingOrdersApi from '~/services/printingOrdersApi';

import { getOrderStatusLabel } from '~/helpers/ordersHelper';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { IconButton } from '~/components/Button';
import {
  Footer,
  FilterBox,
  SelectTitle,
  TableLine,
  FilterContainer,
  CardContainer,
  CardBox,
  CardInfo,
} from './styles';

function CompletedPrinting() {
  const [showLoading, setShowLoading] = useState(false);
  const [page] = useState(1);
  const [limit] = useState(10);
  // const [carrier] = useState(() =>
  //   window.localStorage.getItem('carrier')
  //     ? window.localStorage.getItem('carrier')
  //     : ''
  // );
  const [counter, setCounter] = useState(() =>
    window.localStorage.getItem('counter')
      ? parseInt(window.localStorage.getItem('counter'))
      : 0
  );
  // const [orderState] = useState('all');
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrderCount] = useState(0);

  const [missingCount, setMissingCount] = useState({
    completed: {
      orders: 0,
      items: 0,
    },
    incompleted: {
      orders: 0,
      items: 0,
    },
    one: {
      orders: 0,
      items: 0,
    },
    two: {
      orders: 0,
      items: 0,
    },
    three: {
      orders: 0,
      items: 0,
    },
    four: {
      orders: 0,
      items: 0,
    },
    five: {
      orders: 0,
      items: 0,
    },
    six: {
      orders: 0,
      items: 0,
    },
    seven: {
      orders: 0,
      items: 0,
    },
    eight: {
      orders: 0,
      items: 0,
    },
    nine: {
      orders: 0,
      items: 0,
    },
    gteTen: {
      orders: 0,
      items: 0,
    },
  });

  const [user, setUser] = useState('');
  const [userValue, setUserValue] = useState('');
  const userRef = useRef(null);
  const [carriersLabels, setCarriersLabels] = useState([]);
  // const [carriersOptions, setCarriersOptions] = useState([]);
  const settings = useSelector((state) => state.user.user.settings);

  const printer = settings.find((setting) => setting.key === 'printer')?.value;
  const initialOrderId = settings.find(
    (setting) => setting.key === 'initial_order_id'
  )?.value;
  const finalOrderId = settings.find(
    (setting) => setting.key === 'final_order_id'
  )?.value;

  useEffect(() => {
    async function loadCarriersLabels() {
      const { data } = await api.get('/carriers-labels');

      setCarriersLabels(data);

      // setCarriersOptions(
      //   data.reduce(
      //     (acc, carrierLabel) => {
      //       if (carrierLabel.excluded) {
      //         return acc;
      //       }

      //       const index = acc.findIndex(
      //         (cLabel) => cLabel.label === carrierLabel.label
      //       );

      //       if (index === -1) {
      //         acc.push({
      //           value: carrierLabel.carrier,
      //           label: carrierLabel.label,
      //         });
      //         return acc;
      //       }

      //       acc[index] = {
      //         value: `${acc[index].value},${carrierLabel.carrier}`,
      //         label: carrierLabel.label,
      //       };

      //       return acc;
      //     },
      //     [{ value: '', label: 'TODAS' }]
      //   )
      // );
    }

    loadCarriersLabels();
  }, []);

  const getCarrierLabel = useCallback(
    (carrier) => {
      if (carrier === '') {
        return 'TODAS';
      }

      let carrierLabel = carriersLabels.find((carrierLabel) => {
        return carrier.split(',').includes(carrierLabel.carrier);
      });

      if (carrierLabel) {
        carrierLabel = carrierLabel.label;
      }

      return carrierLabel;
    },
    [carriersLabels]
  );

  const loadOrders = useCallback(async () => {
    setShowLoading(true);

    let response = await api.get('/carriers', { params: { excluded: true } });

    const excludedCarriers = response.data.map((carrier) => carrier.carrier);

    response = await printingOrdersApi.get('/orders', {
      params: {
        counter,
        excluded_carriers: excludedCarriers.join(','),
        initial_order_id: initialOrderId,
        final_order_id: finalOrderId,
      },
    });

    setOrders(
      response.data.limitedOrders.map((order) => {
        return {
          ...order,
          statusLabel: getOrderStatusLabel(order.status),
          synced: order.synced ? 'Sim' : 'Não',
          has_error: order.has_error ? 'Sim' : 'Não',
          carrierLabel: getCarrierLabel(order.carrier),
          isLate: isBefore(parseISO(order.created_at), subDays(new Date(), 6)),
          created_at: format(
            parseISO(order.created_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
          updated_at: format(
            parseISO(order.updated_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
        };
      })
    );

    setOrderCount(response.data.countOrders);

    if (Object.keys(response.data.missingCount).length > 0) {
      setMissingCount(response.data.missingCount);
    }

    setShowLoading(false);
  }, [getCarrierLabel, counter, initialOrderId, finalOrderId]);

  async function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function printOrders() {
    const pOrders = orders.slice(0, 3);

    await api.post('/print-orders', {
      orders: pOrders,
      user,
      printerQueue: printer,
    });
  }

  async function clearBuffer() {
    await api.get('/clear-buffer');
  }

  function handleCounterChange(selectedCounter) {
    setCounter(selectedCounter.value);
    window.localStorage.setItem('counter', selectedCounter.value);
  }

  // function handleOrderStateChange(selectedOrderState) {
  //   setOrderState(selectedOrderState.value);
  //   window.localStorage.setItem('order_state', selectedOrderState.value);
  // }

  async function checkUser() {
    if (!user) {
      throw new Error('Usuário inválido!');
    }

    await api.get(`/users-exists/${user}`);
  }

  function setCompleteUser(userCode) {
    const complement = 'SEPARACAO';
    if (!userCode.includes(complement)) {
      setUser(complement + userCode);
    } else {
      setUser(userCode);
    }
  }

  function clearUser() {
    setUser('');
    setUserValue('');
    focusUser();
  }

  async function handlePrint() {
    setShowLoading(true);
    try {
      await checkUser();
    } catch (error) {
      toast.error('Usuário inválido ou não existe!');
      clearUser();
      setShowLoading(false);
      return;
    }

    await endExpedition();
    await clearBuffer();
    await printOrders();
    await loadOrders();
    clearUser();
    await timeout(2000);

    setShowLoading(false);
  }

  async function endExpedition() {
    await api.post('/end-expedition', { userCode: user });
  }

  // function handleCarrierChange(selectedCarrier) {
  //   setCarrier(selectedCarrier.value);
  //   window.localStorage.setItem('carrier', selectedCarrier.value);
  // }

  function handleUserChange(e) {
    setUserValue(e.target.value);
    setCompleteUser(e.target.value);
  }

  function focusUser() {
    userRef.current.focus();
  }

  function handlePrintSubmit(e) {
    if (showLoading) {
      return;
    }

    if (e.keyCode === 13) {
      handlePrint();
    }
  }

  // async function handleUpdate() {
  //   setShowLoading(true);
  //   await completedOrdersApi.get('/generate-completed-orders');

  //   await loadOrders();
  //   setShowLoading(false);
  // }

  useEffect(() => {
    loadOrders();
  }, [page, limit, loadOrders]);

  return (
    <Container showLoading={showLoading}>
      <PageHeader title={`Pedidos para imprimir: ${ordersCount}`}>
        <input
          name="user"
          placeholder="Usuário"
          value={userValue}
          onChange={(e) => handleUserChange(e)}
          onKeyUp={(e) => handlePrintSubmit(e)}
          ref={userRef}
          style={{
            paddingLeft: 15,
            marginRight: 10,
            borderRadius: 4,
            border: 1,
            borderStyle: 'solid',
            borderColor: '#dddddd',
          }}
          autoFocus
        />
        <IconButton
          title="IMPRIMIR"
          Icon={MdPrint}
          style={{ marginRight: 10 }}
          action={handlePrint}
        />
        {/* <IconButton
          title="ATUALIZAR"
          Icon={MdUpdate}
          background="green"
          action={handleUpdate}
        /> */}
      </PageHeader>

      <FilterContainer>
        {/* <FilterBox>
          <SelectTitle>
            <strong>Transportadora</strong>
          </SelectTitle>
          <Select
            type="text"
            label="Transportadora"
            name="carrier"
            placeholder="Transportadora..."
            classNamePrefix="react-select"
            styles={{
              menu: (provided) => ({
                ...provided,
                maxWidth: 280,
              }),
              control: (provided) => ({
                ...provided,
                height: 45,
                minWidth: 280,
                maxWidth: 280,
              }),
            }}
            value={{ value: carrier, label: getCarrierLabel(carrier) }}
            onChange={handleCarrierChange}
            options={carriersOptions}
          />
        </FilterBox> */}

        <FilterBox>
          <SelectTitle>
            <strong>Quantidade de Itens</strong>
          </SelectTitle>
          <Select
            type="text"
            label="Quantidade"
            name="counter"
            placeholder="Quantidade..."
            classNamePrefix="react-select"
            styles={{
              menu: (provided) => ({
                ...provided,
                maxWidth: 280,
              }),
              control: (provided) => ({
                ...provided,
                height: 45,
                minWidth: 280,
                maxWidth: 280,
              }),
            }}
            value={{
              value: counter,
              label:
                counter > 0 ? (counter > 20 ? '21 OU MAIS' : counter) : 'TODOS',
            }}
            onChange={handleCounterChange}
            options={Array.apply(null, Array(22)).map(function (x, i) {
              return {
                value: i,
                label: i > 0 ? (i > 20 ? '21 OU MAIS' : i) : 'TODOS',
              };
            })}
          />
        </FilterBox>

        {/* <FilterBox>
          <SelectTitle>
            <strong>Estado dos Pedidos</strong>
          </SelectTitle>
          <Select
            type="text"
            label="State"
            name="order_state"
            placeholder="Estado..."
            classNamePrefix="react-select"
            styles={{
              menu: (provided) => ({
                ...provided,
                maxWidth: 280,
              }),
              control: (provided) => ({
                ...provided,
                height: 45,
                minWidth: 280,
                maxWidth: 280,
              }),
            }}
            value={{
              value: orderState,
              label: orderState === 'all' ? 'TODOS' : 'EM ATRASO',
            }}
            onChange={handleOrderStateChange}
            options={[
              { value: 'all', label: 'TODOS' },
              { value: 'overdue', label: 'EM ATRASO' },
            ]}
          />
        </FilterBox> */}
      </FilterContainer>

      <Table>
        <thead>
          <tr>
            <th>ID do Site</th>
            <th>ID do Sisplan</th>
            <th>Status</th>
            <th>Total de Itens</th>
            <th>Itens Faltando</th>
            <th>Transportadora</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <TableLine key={order.id} isLate={order.isLate}>
              <td>{order.order_id}</td>
              <td>{order.sisplan_id}</td>
              <td>{order.statusLabel}</td>
              <td>{order.items_count}</td>
              <td>{order.items_missing}</td>
              <td>{order.carrierLabel}</td>
              <td>{order.created_at}</td>
              <td>{order.updated_at}</td>
              <td />
            </TableLine>
          ))}
        </tbody>
      </Table>

      <Footer>
        <CardContainer>
          <CardBox>
            <h2>Completos</h2>
            <div>
              <CardInfo>
                <h3>Pedidos</h3>
                <p>{missingCount.completed.orders}</p>
              </CardInfo>
              <CardInfo>
                <h3>Itens</h3>
                <p>{missingCount.completed.items}</p>
              </CardInfo>
            </div>
          </CardBox>

          {missingCount.one.orders > 0 && (
            <CardBox>
              <h2>Faltando 1 Item</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.one.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.one.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.two.orders > 0 && (
            <CardBox>
              <h2>Faltando 2 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.two.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.two.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.three.orders > 0 && (
            <CardBox>
              <h2>Faltando 3 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.three.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.three.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.four.orders > 0 && (
            <CardBox>
              <h2>Faltando 4 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.four.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.four.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.five.orders > 0 && (
            <CardBox>
              <h2>Faltando 5 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.five.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.five.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.six.orders > 0 && (
            <CardBox>
              <h2>Faltando 6 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.six.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.six.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.seven.orders > 0 && (
            <CardBox>
              <h2>Faltando 7 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.seven.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.seven.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.eight.orders > 0 && (
            <CardBox>
              <h2>Faltando 8 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.eight.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.eight.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.nine.orders > 0 && (
            <CardBox>
              <h2>Faltando 9 Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.nine.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.nine.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.gteTen.orders > 0 && (
            <CardBox>
              <h2>Faltando 10 ou mais Itens</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.gteTen.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.gteTen.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}

          {missingCount.incompleted.orders > 0 && (
            <CardBox>
              <h2>Pedidos Incompletos</h2>
              <div>
                <CardInfo>
                  <h3>Pedidos</h3>
                  <p>{missingCount.incompleted.orders}</p>
                </CardInfo>
                <CardInfo>
                  <h3>Itens</h3>
                  <p>{missingCount.incompleted.items}</p>
                </CardInfo>
              </div>
            </CardBox>
          )}
        </CardContainer>
      </Footer>
    </Container>
  );
}

export default CompletedPrinting;
