import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import { Input } from '~/components/Form';
import { BackButton, SaveButton } from '~/components/Button';

import { Content } from './styles';

function UserForm() {
  const [showLoading, setShowLoading] = useState(false);

  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    formRef.current.setErrors({});

    try {
      setShowLoading(true);

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await api.get('/users', {
        params: { limit: 1, sortOrder: 'DESC' },
      });
      const lastValue =
        parseInt(response.data.rows[0].code.replace(/[^0-9]/g, '')) + 1;
      const code = `SEPARACAO${lastValue}`;

      data.code = code;
      data.password = '123';
      data.email = `${code.toLowerCase()}@lojasyec.com`;
      await api.post('/users', data);

      setShowLoading(false);

      toast.success('Cadastro realizado com sucesso!');

      history.push('/usuarios');
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      setShowLoading(false);
    }
  }

  return (
    <Container showLoading={showLoading}>
      <PageHeader title="Cadastro de Usuários">
        <BackButton to="/usuarios" />
        <SaveButton action={() => formRef.current.submitForm()} />
      </PageHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Input
              type="text"
              label="Nome"
              name="name"
              placeholder="SEPARACAO"
            />
          </div>
        </Form>
      </Content>
    </Container>
  );
}

export default UserForm;
