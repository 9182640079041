import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { BackButton, SaveButton } from '~/components/Button';
import { Input, Select, Textarea } from '~/components/Form';

import { Content } from './styles';

function ReturnEdit({ match }) {
  const { id } = match.params;

  const [showLoading, setShowLoading] = useState(false);
  const [bonus, setBonus] = useState();
  const [status, setStatus] = useState();
  const [products, setProducts] = useState([]);

  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    formRef.current.setErrors({});

    try {
      setShowLoading(true);

      await api.put(`/return-orders/${id}`, {
        ...data,
        status: status.value,
        bonus: bonus.value,
        products,
      });

      setShowLoading(false);

      toast.success('Atualização realizada com sucesso!');

      history.push('/pedidos/troca-devolucao');
      reset();
    } catch (err) {
      console.log(err);
      setShowLoading(false);
    }
  }

  function handleStatusChange(selectedStatus) {
    setStatus(selectedStatus);
  }

  function handleBonusChange(selectedBonus) {
    setBonus(selectedBonus);
  }

  function getStatusDisplayName(s) {
    switch (s) {
      case 'aguardando-produto':
        return 'Aguardando Produto';

      case 'aguardando-resposta-cliente':
        return 'Aguardando Resposta do Cliente';

      case 'cliente-notificado':
        return 'Cliente Notificado';

      case 'realizar-entrada-nf':
        return 'Realizar Entrada de NF';

      case 'entrada-nf-realizada':
        return 'Entrada de NF Realizada';

      case 'realizar-estorno':
        return 'Realizar Estorno';

      case 'estorno-realizado':
        return 'Estorno Realizado';

      default:
        return s;
    }
  }

  function getBonusDisplayName(b) {
    switch (b) {
      case 'exchange':
        return 'Troca';

      case 'reversal':
        return 'Extorno';

      case 'exchanged_order':
        return 'Pedido Trocado';

      default:
        return b;
    }
  }

  // function getMotiveDisplayName(m) {
  //   switch (m) {
  //     case 'size_exchange':
  //       return 'Troca de tamanho';

  //     case 'exchanged_product':
  //       return 'Produto Trocado';

  //     case 'modeling':
  //       return 'Modelagem';

  //     case 'defect':
  //       return 'Defeito';

  //     case 'reversal':
  //       return 'Devolução';

  //     default:
  //       return m;
  //   }
  // }

  useEffect(() => {
    async function loadReturn() {
      if (id) {
        const { data } = await api.get(`return-orders/${id}`);

        setStatus(data.status);
        setBonus(data.bonus);

        formRef.current.setData(data);
        formRef.current.setFieldValue('status', {
          value: data.status,
          label: getStatusDisplayName(data.status),
        });

        formRef.current.setFieldValue('bonus', {
          value: data.bonus,
          label: getBonusDisplayName(data.bonus),
        });

        setProducts(data.products);
      }
    }

    loadReturn();
  }, [id]);

  const statusOptions = [
    {
      value: 'aguardando-produto',
      label: 'Aguardando Produto',
    },
    {
      value: 'aguardando-resposta-cliente',
      label: 'Aguardando Resposta do Cliente',
    },
    {
      value: 'cliente-notificado',
      label: 'Cliente Notificado',
    },
    {
      value: 'realizar-entrada-nf',
      label: 'Realizar Entrada de NF',
    },
    {
      value: 'entrada-nf-realizada',
      label: 'Entrada de NF Realizada',
    },
    {
      value: 'realizar-estorno',
      label: 'Realizar Estorno',
    },
    {
      value: 'estorno-realizado',
      label: 'Estorno Realizado',
    },
  ];

  const bonusOptions = [
    { value: 'exchange', label: 'Troca' },
    { value: 'reversal', label: 'Devolução' },
    { value: 'exchanged_order', label: 'Pedido Trocado' },
  ];

  const motiveOptions = [
    { value: 'size_exchange', label: 'Troca de tamanho' },
    { value: 'exchanged_product', label: 'Produto Trocado' },
    { value: 'modeling', label: 'Modelagem' },
    { value: 'defect', label: 'Defeito' },
    { value: 'reversal', label: 'Devolução' },
  ];

  return (
    <Container showLoading={showLoading}>
      <PageHeader title="Cadastro de Troca e Devolução">
        <BackButton to="/pedidos/troca-devolucao" />
        <SaveButton action={() => formRef.current.submitForm()} />
      </PageHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Input
              type="text"
              label="Pedido do Site"
              name="order_id"
              disabled
            />
            <Input
              type="text"
              label="Pedido do Sisplan"
              name="sisplan_id"
              disabled
            />
          </div>
          <div>
            <Select
              type="text"
              label="Status"
              name="status"
              placeholder="Selecione..."
              onChange={handleStatusChange}
              options={statusOptions}
            />
            <Select
              type="text"
              label="Bonificação"
              name="bonus"
              placeholder="Selecione..."
              onChange={handleBonusChange}
              options={bonusOptions}
            />
            <Input type="text" label="Código de Retorno" name="return_code" />
          </div>
          <div>
            <Textarea
              name="observation"
              label="Observação"
              cols="65"
              rows="10"
            />
            <Textarea
              name="additional_data"
              label="Informações Adicionais"
              cols="65"
              rows="10"
            />
          </div>

          <div>
            <Table condensed>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Quantidade</th>
                  <th>Preço</th>
                  <th>Motivo</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td>{product.sku}</td>
                    <td>{product.quantity}</td>
                    <td>{product.price}</td>
                    <td>
                      <Select
                        type="text"
                        name="motive"
                        placeholder="Selecione..."
                        // defaultInputValue={getMotiveDisplayName(product.motive)}
                        onChange={(selectedMotive) => {
                          const prods = products.map((p) => {
                            if (p.id === product.id) {
                              return {
                                ...p,
                                motive: selectedMotive.value,
                              };
                            }

                            return p;
                          });
                          setProducts(prods);
                        }}
                        options={motiveOptions}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Form>
      </Content>
    </Container>
  );
}

export default ReturnEdit;
