import React from 'react';
import ReactLoading from 'react-loading';

import colors from '~/styles/colors';

import { Wrapper, LoadingContainer } from './styles';

function Container({ showLoading, children }) {
  return (
    <>
      <Wrapper>{children}</Wrapper>
      <LoadingContainer show={showLoading}>
        <ReactLoading type="spinningBubbles" color={colors.primary} />
      </LoadingContainer>
    </>
  );
}

export default Container;
