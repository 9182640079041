import React, { useState, useEffect, useCallback } from 'react';
import { MdStop } from 'react-icons/md';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';

import Table from '~/components/Table';
import PageHeader from '~/components/PageHeader';
import TableActions from '~/components/TableActions';

import { Container } from './styles';

function Jobs() {
  const [jobs, setJobs] = useState([]);

  function getStatusDisplayName(status) {
    switch (status) {
      case 'stopped':
        return 'Parado';

      case 'running':
        return 'Rodando';

      case 'disabled':
        return 'Desabilitado';

      default:
        return status;
    }
  }

  const loadJobs = useCallback(async () => {
    const response = await api.get('/jobs');

    setJobs(
      response.data.data.map((job) => ({
        ...job,
        status: getStatusDisplayName(job.status),
        updated_at: format(parseISO(job.updated_at), 'dd/MM/yyyy HH:mm:ss', {
          locale: pt,
        }),
        created_at: format(parseISO(job.created_at), 'dd/MM/yyyy HH:mm:ss', {
          locale: pt,
        }),
      }))
    );
  }, []);

  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  async function stopService(id) {
    try {
      await api.post('/jobs', { id });
    } catch (error) {
      toast.error('Serviço já se encontra parado');
    }
    await loadJobs();
  }

  return (
    <Container>
      <PageHeader title="Serviços">
        {/* <FilterButton Icon={FiFilter} background="#333" size={14} /> */}
        {/* <RegisterButton to="/pedidos/troca-devolucao/cadastro" /> */}
      </PageHeader>
      <Table>
        <thead>
          <tr>
            <th>Serviço</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr>
              <td>{job.label}</td>
              <td>{job.created_at}</td>
              <td>{job.updated_at}</td>
              <td>{job.status}</td>
              <td>
                <TableActions>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        stopService(job.id);
                      }}
                    >
                      <MdStop />
                      Parar
                    </button>
                  </div>
                </TableActions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Jobs;
