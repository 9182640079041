import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/services/api';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import { Input, Select, Textarea } from '~/components/Form';
import { BackButton, SaveButton } from '~/components/Button';

import { Content } from './styles';

function GoalForm() {
  const [showLoading, setShowLoading] = useState(false);
  const [showType, setShowType] = useState(false);
  const [dataForm, setDataForm] = useState();

  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    formRef.current.setErrors({});

    try {
      setShowLoading(true);

      const schema = Yup.object().shape({
        date: Yup.string()
          .required('Data da meta é obrigatório')
          .matches(/(\d{2}-\d{4})/, 'Data deve ter o seguinte formato 03-2021'),
        value: Yup.number('Valor precisa ser maior que 0')
          .required('Valor da meta é obrigatório')
          .typeError('Valor da meta é obrigatório'),
        indicator: Yup.string().required('Indicador da meta é obrigatório'),
        sector_id: Yup.number()
          .required('Setor da meta é obrigatório')
          .typeError('Setor da meta é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      await api.post('/goals', data);

      setShowLoading(false);

      toast.success('Cadastro realizado com sucesso!');

      setDataForm(data);
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      setShowLoading(false);
    }
  }

  function getIndicatorDisplayName(selectedIndicator) {
    switch (selectedIndicator) {
      case 'stock_entry':
        return 'Entrada Estoque';
      case 'separation':
        return 'Separação';
      case 'billing':
        return 'Faturamento';
      case 'negotiation':
        return 'Negociação';
      case 'production':
        return 'Produção';
      case 'billing_error':
        return 'Erro de faturamento';
      case 'procon_and_reclame_aqui':
        return 'Procon e Reclame aqui';
      case 'draft':
        return 'Minuta';
      default:
        return '';
    }
  }

  const handleIndicator = (indicador) => {
    if (indicador) {
      if (indicador.value === 'production') {
        setShowType(true);
      } else {
        setShowType(false);
      }
    }
  };

  useEffect(() => {
    const loadIndicatorShowType = () => {
      if (dataForm) {
        formRef.current.setFieldValue('indicator', {
          value: dataForm.indicator,
          label: getIndicatorDisplayName(dataForm.indicator),
        });

        if (dataForm.indicator === 'production') {
          setShowType(true);
        }
      } else {
        formRef.current.reset();
      }
    };
    loadIndicatorShowType();
  }, [dataForm]);

  const typeOptions = [
    {
      value: 'internal',
      label: 'Interno',
    },
    {
      value: 'external',
      label: 'Externo',
    },
  ];
  const indicatorOptions = [
    {
      label: 'Entrada Estoque',
      value: 'stock_entry',
    },
    {
      label: 'Separação',
      value: 'separation',
    },
    {
      label: 'Faturamento',
      value: 'billing',
    },
    {
      label: 'Negociação',
      value: 'negotiation',
    },
    {
      label: 'Produção',
      value: 'production',
    },
    {
      label: 'Erro de faturamento',
      value: 'billing_error',
    },
    {
      label: 'Procon e Reclame aqui',
      value: 'procon_and_reclame_aqui',
    },
    {
      label: 'Minuta',
      value: 'draft',
    },
  ];

  return (
    <Container showLoading={showLoading}>
      <PageHeader title="Cadastro de Metas">
        <BackButton to="/metas" />
        <SaveButton action={() => formRef.current.submitForm()} />
      </PageHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Select
              name="indicator"
              label="indicador"
              onChange={handleIndicator}
              placeholder="Selecionar..."
              options={indicatorOptions}
            />
            <Input type="text" label="Data" name="date" placeholder="03-2021" />
          </div>
          <div>
            {showType ? (
              <Select
                type="text"
                label="Tipo"
                name="type"
                placeholder="Selecione..."
                options={typeOptions}
              />
            ) : null}
            <Input
              type="number"
              label="Valor"
              name="value"
              defaultValue="0"
              placeholder="4000000"
            />
          </div>
          <div>
            <Input
              type="number"
              label="Setor"
              name="sector_id"
              placeholder="Setor"
            />
            <Textarea
              name="description"
              label="Descrição"
              placeholder="Descrição"
              cols="30"
              rows="5"
            />
          </div>
        </Form>
      </Content>
    </Container>
  );
}

export default GoalForm;
