import React, { useState, useEffect, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import Select from 'react-select';
import Pagination from 'rc-pagination';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';

import { getOrderStatusLabel, getOrderErrorType } from '~/helpers/ordersHelper';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { MdAutorenew } from 'react-icons/md';
import { IconButton } from '~/components/Button';

function SyncingError() {
  const [showLoading, setShowLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [limit] = useState(10);
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrderCount] = useState(0);

  const loadOrders = useCallback(async () => {
    setShowLoading(true);
    const response = await api.get('/orders', {
      params: {
        page,
        limit,
        sortOrder: 'ASC',
        printed: false,
        synced: false,
        has_error: true,
        status,
      },
    });

    const errorOrders = [];

    /* eslint-disable no-restricted-syntax  */
    /* eslint-disable no-await-in-loop */
    for (const order of response.data.rows) {
      const orderData = JSON.parse(order.data);
      const errorType = await getOrderErrorType(orderData);

      errorOrders.push({
        ...order,
        statusLabel: getOrderStatusLabel(order.status),
        synced: order.synced ? 'Sim' : 'Não',
        has_error: order.has_error ? 'Sim' : 'Não',
        errorType: errorType.join(', '),
        created_at: format(parseISO(order.createdAt), 'dd/MM/yyyy HH:mm:ss', {
          locale: pt,
        }),
        updated_at: format(parseISO(order.updatedAt), 'dd/MM/yyyy HH:mm:ss', {
          locale: pt,
        }),
      });
    }

    setOrders(errorOrders);

    setOrderCount(response.data.count);

    setShowLoading(false);
  }, [page, limit, status]);

  useEffect(() => {
    loadOrders();
  }, [page, limit, loadOrders]);

  function handlePagination(current) {
    setPage(current);
  }

  function handleStatusChange(selectedStatus) {
    setStatus(selectedStatus.value);
  }

  async function handleResync() {
    setShowLoading(true);
    await api.put('/orders');
    loadOrders();
    setShowLoading(false);
  }

  const statusOptions = [
    { value: 'new,processing', label: 'Todos' },
    { value: 'new', label: 'Aguardando Pagamento' },
    { value: 'processing', label: 'Pagamento Aprovado' },
  ];

  return (
    <Container showLoading={showLoading}>
      <PageHeader title={`Pedidos com erro para sincronizar: ${ordersCount}`}>
        <IconButton
          title="RESINCRONIZAR"
          Icon={MdAutorenew}
          action={handleResync}
        />
      </PageHeader>
      <Select
        type="text"
        label="Status"
        name="status"
        placeholder="Status..."
        classNamePrefix="react-select"
        styles={{
          menu: (provided) => ({
            ...provided,
            maxWidth: 280,
          }),
          control: (provided) => ({
            ...provided,
            height: 45,
            minWidth: 280,
            maxWidth: 280,
          }),
        }}
        onChange={handleStatusChange}
        options={statusOptions}
      />

      <Table>
        <thead>
          <tr>
            <th>ID do Site</th>
            <th>ID do Sisplan</th>
            <th>Status</th>
            <th>Tipo do Erro</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.order_id}</td>
              <td>{order.sisplan_id}</td>
              <td>{order.statusLabel}</td>
              <td>{order.errorType}</td>
              <td>{order.created_at}</td>
              <td>{order.updated_at}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        current={page}
        pageSize={limit}
        total={ordersCount}
        onChange={handlePagination}
      />
    </Container>
  );
}

export default SyncingError;
