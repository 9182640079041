import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import { BackButton, SaveButton } from '~/components/Button';
import { Input } from '~/components/Form';

import { Content } from './styles';

function UserEdit({ match }) {
  const id = match.params;

  const [showLoading, setShowLoading] = useState(false);

  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    try {
      setShowLoading(true);

      await api.put(`/users/${id.id}`, data);

      setShowLoading(false);

      toast.success('Atualização realizada com sucesso!');

      history.push('/usuarios');
      reset();
    } catch (err) {
      setShowLoading(false);
    }
  }
  useEffect(() => {
    async function loadUsers() {
      if (id) {
        const { data } = await api.get(`users/${id.id}`);
        formRef.current.setData(data);
      }
    }

    loadUsers();
  }, [id]);

  return (
    <Container showLoading={showLoading}>
      <PageHeader title="Editar Usuário">
        <BackButton to="/usuarios" />
        <SaveButton action={() => formRef.current.submitForm()} />
      </PageHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Input type="text" label="Nome" name="name" />
          </div>
        </Form>
      </Content>
    </Container>
  );
}

export default UserEdit;
