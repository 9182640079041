import React from 'react';
import { FiMenu } from 'react-icons/fi';

import Navbar from '~/components/Navbar';

import { Container, HeaderContainer } from './styles';

function Header({ isOpen, handleIsOpen }) {
  return (
    <Container>
      <HeaderContainer isOpen={isOpen}>
        <FiMenu color="#fff" size={22} onClick={handleIsOpen} />
      </HeaderContainer>

      <Navbar isOpen={isOpen} />
    </Container>
  );
}

export default Header;
