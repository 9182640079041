import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { Wrapper, Label, Input, Error } from './styles';

export default function SimpleInput({ name, label, flex, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <Wrapper flex={flex}>
      <Label htmlFor={fieldName}>{label}</Label>
      <Input
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <Error className="error">{error}</Error>}
    </Wrapper>
  );
}
