import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { store } from '~/store';
import api from '~/services/api';

import { updateSettingsRequest } from '~/store/modules/user/actions';

import { Content } from './styles';
import { SaveButton } from '~/components/Button';
import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import { Input } from '~/components/Form';

function SettingsForm() {
  const dispatch = useDispatch();

  const [showLoading, setShowLoading] = useState(false);
  const [userId, setUserId] = useState();
  const formRef = useRef(null);

  async function handleSubmit(data) {
    setShowLoading(true);
    // try {
    // await api.post(`/settings`, { ...data, user: userId });

    dispatch(updateSettingsRequest({ ...data, user_id: userId }));

    //   toast.success('Cadastro realizado com sucesso!');
    // } catch (err) {
    //   toast.error('Erro ao salvar Configurações');
    // }
    setShowLoading(false);
  }

  useEffect(() => {
    const loadSettings = async () => {
      setShowLoading(true);
      try {
        const { user } = await store.getState().user;
        setUserId(user.id);
        const { data } = await api.get('/settings', {
          params: {
            user_id: user.id,
          },
        });

        data.forEach((setting) => {
          formRef.current.setFieldValue(setting.key, setting.value);
        });
      } catch (err) {
        toast.error('Erro ao carregar configurações!');
      }
      setShowLoading(false);
    };
    loadSettings();
  }, []);

  return (
    <Container showLoading={showLoading}>
      <PageHeader title="Configurações">
        <SaveButton action={() => formRef.current.submitForm()} />
      </PageHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            type="text"
            label="Impressora"
            name="printer"
            placeholder="Impressora"
            flex={1}
          />
          <Input
            type="text"
            label="ID inicial do pedido"
            name="initial_order_id"
            placeholder="ID inicial do pedido"
            flex={1}
          />
          <Input
            type="text"
            label="ID final do pedido"
            name="final_order_id"
            placeholder="ID final do pedido"
            flex={1}
          />
        </Form>
      </Content>
    </Container>
  );
}

export default SettingsForm;
