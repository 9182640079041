import React from 'react';

import { Button } from './styles';

export default function IconButton({
  title,
  Icon,
  action,
  color = '#fff',
  size = 22,
  background = '#333',
  ...rest
}) {
  return (
    <Button onClick={action} background={background} {...rest} color={color}>
      {Icon ? <Icon color={color} size={size} /> : ''}
      {title}
    </Button>
  );
}
