import React from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import colors from '~/styles/colors';

import { Input } from '~/components/Form';
import { IconButton } from '~/components/Button';
import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';

import { FormContainer } from './styles';

function Fetch() {
  async function handleSubmit({ numero }, { reset }) {
    try {
      await api.get(`/fetch-m2-order`, { params: { orderId: numero } });

      toast.success('Pedido Atualizado!');
      reset();
    } catch (error) {
      toast.error('Erro: pedido não foi atualizado!');
      console.log(error);
    }
  }

  return (
    <Container>
      <PageHeader title="Atualizar pedido" />
      <FormContainer onSubmit={handleSubmit}>
        <Input
          name="numero"
          type="text"
          label="Número do Pedido"
          placeholder=""
        />

        <IconButton title="Atualizar pedido" background={colors.primary} />
      </FormContainer>
    </Container>
  );
}

export default Fetch;
