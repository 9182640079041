import React, { useEffect, useState } from 'react';
import { MdModeEdit } from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';

import colors from '~styles/colors';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';
import { RegisterButton } from '~/components/Button';

function Carriers() {
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    async function loadCarriers() {
      const { data } = await api.get('/carriers');

      setCarriers(data);
    }

    loadCarriers();
  }, [])

  return (
    <Container>
      <PageHeader title="Transportadoras">
        <RegisterButton to="/transportadoras/cadastro" />
      </PageHeader>
  
      <Table>
        <thead>
          <tr>
            <th>Código Sisplan</th>
            <th>Código Datafrete</th>
            <th>Nome</th>
            <th>CEP Inicial</th>
            <th>CEP Final</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {carriers.map((carrier) => (
            <tr key={carrier.id}>
              <td>{carrier.sisplan_carrier}</td>
              <td>{carrier.carrier}</td>
              <td>{carrier.label}</td>
              <td>{carrier.initial_postcode}</td>
              <td>{carrier.final_postcode}</td>
              <td>
                <TableActions>
                  <div>
                    <button
                      type="button"
                      onClick={() => history.push(`/transportadoras/editar/${carrier.sisplan_carrier}`)}
                    >
                      <MdModeEdit color={colors.actions.edit} size={24} />
                      Atualizar Transportadora
                    </button>
                  </div>                
                </TableActions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Carriers;
