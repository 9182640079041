import React from 'react';
import { useDispatch } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';

import { IconButton } from '~/components/Button';

import { Container, NavHeader, NavList } from './styles';

export default function Navbar({ isOpen }) {
  const dispatch = useDispatch();

  function handleSignOut(e) {
    e.preventDefault();

    dispatch(signOut());
  }

  return (
    <Container isOpen={isOpen}>
      <NavList>
        <NavHeader>
          <span>Y&C</span>
        </NavHeader>
        <li>
          <a href="/dashboard">
            <span>Dashboard</span>
          </a>
        </li>

        {/* <li>
          <a href="/produtos">
            <span>Produtos</span>
          </a>
          <ul>
            <li>
              <a href="/produtos/of-futura">OF Futura</a>
            </li>
          </ul>
        </li> */}

        <li>
          <a href="/pedidos">
            <span>Pedidos</span>
          </a>
          <ul>
            <li>
              <a href="/pedidos/impressao">Impressão</a>
            </li>
            <li>
              <a href="/pedidos/impressao-entrega-rapido">
                Impressão Envio Rápido
              </a>
            </li>
            <li>
              <a href="/pedidos/impressao-usuario">Impressão por Usuário</a>
            </li>
            <li>
              <a href="/pedidos/impressao-completos">
                Impressão Pedidos Completos
              </a>
            </li>
            <li>
              <a href="/pedidos/impressao-incompletos">
                Impressão Pedidos Incompletos
              </a>
            </li>
            <li>
              <a href="/pedidos/impressao-individual">
                Impressão Pedidos Individual
              </a>
            </li>
            <li>
              <a href="/pedidos/impressao-selecionados">
                Impressão Pedidos Selecionados
              </a>
            </li>
            {/* <li>
              <a href="/pedidos/finalizar-expedicao">Finalizar Expedição</a>
            </li> */}
            {/* <li>
              <a href="/pedidos/erro-impressao">Impressão com erro</a>
            </li> */}
            {/* <li>
              <a href="/pedidos/interno">Interno</a>
            </li> */}
            {/* <li>
              <a href="/pedidos/troca-devolucao">Troca e Devolução</a>
            </li> */}
            <li>
              <a href="/pedidos/cancelar-reserva">Cancelar Reserva</a>
            </li>
            <li>
              <a href="/pedidos/buscar">Buscar Pedido</a>
            </li>
            <li>
              <a href="/pedidos/erro-sincronizacao">Sincronização com erro</a>
            </li>
          </ul>
        </li>

        <li>
          <a href="/transportadoras">
            <span>Transportadoras</span>
          </a>
        </li>

        <li>
          <a href="/metas">
            <span>Metas</span>
          </a>
        </li>

        <li>
          <a href="/usuarios">
            <span>Usuários</span>
          </a>
        </li>

        <li>
          <a href="/servicos">
            <span>Serviços</span>
          </a>
        </li>

        <li>
          <a href="/configuracoes">
            <span>Configurações</span>
          </a>
        </li>

        <li>
          <IconButton title="Sair" action={handleSignOut} />
        </li>
      </NavList>
    </Container>
  );
}
