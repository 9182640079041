import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdPrint } from 'react-icons/md';
import { format, isBefore, parseISO, subDays } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';

import { getOrderStatusLabel } from '~/helpers/ordersHelper';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { IconButton } from '~/components/Button';
import { TableLine } from './styles';

function SelectedPrinting() {
  const [showLoading, setShowLoading] = useState(false);
  const [page] = useState(1);
  const [limit] = useState(10);
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrderCount] = useState(0);

  const [user, setUser] = useState('');
  const [userValue, setUserValue] = useState('');
  const userRef = useRef(null);
  const [carriersLabels, setCarriersLabels] = useState([]);
  const settings = useSelector((state) => state.user.user.settings);

  const printer = settings.find((setting) => setting.key === 'printer')?.value;
  const initialOrderId = settings.find(
    (setting) => setting.key === 'initial_order_id'
  )?.value;
  const finalOrderId = settings.find(
    (setting) => setting.key === 'final_order_id'
  )?.value;

  useEffect(() => {
    async function loadCarriersLabels() {
      const { data } = await api.get('/carriers-labels');

      setCarriersLabels(data);
    }

    loadCarriersLabels();
  }, []);

  const getCarrierLabel = useCallback(
    (carrier) => {
      if (carrier === '') {
        return 'TODAS';
      }

      let carrierLabel = carriersLabels.find((carrierLabel) => {
        return carrier.split(',').includes(carrierLabel.carrier);
      });

      if (carrierLabel) {
        carrierLabel = carrierLabel.label;
      }

      return carrierLabel;
    },
    [carriersLabels]
  );

  const loadOrders = useCallback(async () => {
    setShowLoading(true);

    let response = await api.get('/carriers', { params: { excluded: true } });

    const excludedCarriers = response.data.map((carrier) => carrier.carrier);

    response = await api.get('/selected-orders', {
      params: {
        excluded_carriers: excludedCarriers.join(','),
        initialOrderId,
        finalOrderId,
      },
    });

    console.log(response);

    setOrders(
      response.data.selectedOrders.rows.map((order) => {
        return {
          ...order,
          statusLabel: getOrderStatusLabel(order.status),
          synced: order.synced ? 'Sim' : 'Não',
          has_error: order.has_error ? 'Sim' : 'Não',
          carrierLabel: getCarrierLabel(order.carrier),
          isLate: isBefore(parseISO(order.created_at), subDays(new Date(), 6)),
          created_at: format(
            parseISO(order.created_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
          updated_at: format(
            parseISO(order.updated_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
        };
      })
    );

    setOrderCount(response.data.selectedOrders.count);

    setShowLoading(false);
  }, [getCarrierLabel, initialOrderId, finalOrderId]);

  async function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function printOrders() {
    const pOrders = orders.slice(0, 3);

    await api.post('/print-orders', {
      orders: pOrders,
      user,
      printerQueue: printer,
    });
  }

  async function clearBuffer() {
    await api.get('/clear-buffer');
  }

  async function checkUser() {
    if (!user) {
      throw new Error('Usuário inválido!');
    }

    await api.get(`/users-exists/${user}`);
  }

  function setCompleteUser(userCode) {
    const complement = 'SEPARACAO';
    if (!userCode.includes(complement)) {
      setUser(complement + userCode);
    } else {
      setUser(userCode);
    }
  }

  function clearUser() {
    setUser('');
    setUserValue('');
    focusUser();
  }

  async function handlePrint() {
    setShowLoading(true);
    try {
      await checkUser();
    } catch (error) {
      toast.error('Usuário inválido ou não existe!');
      clearUser();
      setShowLoading(false);
      return;
    }

    await endExpedition();
    await clearBuffer();
    await printOrders();
    await loadOrders();
    clearUser();
    await timeout(2000);

    setShowLoading(false);
  }

  async function endExpedition() {
    await api.post('/end-expedition', { userCode: user });
  }

  function handleUserChange(e) {
    setUserValue(e.target.value);
    setCompleteUser(e.target.value);
  }

  function focusUser() {
    userRef.current.focus();
  }

  function handlePrintSubmit(e) {
    if (showLoading) {
      return;
    }

    if (e.keyCode === 13) {
      handlePrint();
    }
  }

  useEffect(() => {
    loadOrders();
  }, [page, limit, loadOrders]);

  return (
    <Container showLoading={showLoading}>
      <PageHeader title={`Pedidos para imprimir: ${ordersCount}`}>
        <input
          name="user"
          placeholder="Usuário"
          value={userValue}
          onChange={(e) => handleUserChange(e)}
          onKeyUp={(e) => handlePrintSubmit(e)}
          ref={userRef}
          style={{
            paddingLeft: 15,
            marginRight: 10,
            borderRadius: 4,
            border: 1,
            borderStyle: 'solid',
            borderColor: '#dddddd',
          }}
          autoFocus
        />
        <IconButton
          title="IMPRIMIR"
          Icon={MdPrint}
          style={{ marginRight: 10 }}
          action={handlePrint}
        />
      </PageHeader>

      <Table>
        <thead>
          <tr>
            <th>ID do Site</th>
            <th>ID do Sisplan</th>
            <th>Status</th>
            <th>Transportadora</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <TableLine key={order.order_id}>
              <td>{order.order_id}</td>
              <td>{order.sisplan_id}</td>
              <td>{order.statusLabel}</td>
              <td>{order.carrierLabel}</td>
              <td>{order.created_at}</td>
              <td>{order.updated_at}</td>
              <td />
            </TableLine>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default SelectedPrinting;
