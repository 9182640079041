import styled from 'styled-components';

import colors from '~/styles/colors';

export const Title = styled.h1`
  color: ${colors.black};
`;

export const Content = styled.div`
  padding: 20px;
  margin-top: 35px;
  border-radius: 4px;
  background: ${colors.white};

  form {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
    }

    /* > section {
      display: flex;
      flex-direction: row;d

      margin-bottom: 15px;
      margin-right: 15px;
    } */
  }
`;

export const CommentList = styled.ul`
  position: absolute;
  width: 500px;
  height: 272px;
  overflow-y: scroll;
  right: 20px;
`;
