import styled from 'styled-components';

export const Container = styled.nav`
  left: ${(props) => (props.isOpen ? 0 : '-12rem')};
  width: 12rem;
  height: 100vh;
  position: fixed;
  background-color: white;
  box-shadow: 2px 0px 1px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
`;

export const NavHeader = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  ::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0px;
  }
  li {
    width: 100%;
    padding: 0.6rem;

    a {
      color: #000;
      font-weight: bold;

      &:active {
        color: #000;
      }
    }
  }
`;
