import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';

import { Input } from '~/components/Form';

import { signInRequest } from '~/store/modules/auth/actions';

function SignIn() {
  const dispatch = useDispatch();

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          name="email"
          type="email"
          label="SEU E-MAIL"
          placeholder="seu@email.com.br"
        />
        <Input
          name="password"
          type="password"
          label="SUA SENHA"
          placeholder="**********"
        />

        <button type="submit">Entrar no sistema</button>
      </Form>
    </>
  );
}

export default SignIn;
