import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  margin-left: ${(props) => (props.isOpen ? '12rem' : 0)};
  transition: 0.3s ease-in-out;
  padding: 0.5rem;

  color: white;
`;
