import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { Wrapper, Label, Textarea, Error } from './styles';

export default function TextareaInput({ name, label, ...rest }) {
  const textareaRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <Wrapper>
      <Label htmlFor={fieldName}>{label}</Label>
      <Textarea
        id={fieldName}
        ref={textareaRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <Error className="error">{error}</Error>}
    </Wrapper>
  );
}
