export default {
  primary: '#000000',
  white: '#ffffff',
  black: '#000000',
  lightGray: '#dddddd',
  gray: '#999999',
  darkGray: '#444444',
  darkRed: '#B0171F',

  actions: {
    view: '#8e5be8',
    edit: '#4d85ee',
    delete: '#de3b3b',
  },
};
