import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import { MdModeEdit } from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';

import colors from '~/styles/colors';

import Container from '~/components/Container';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';
import Modal from '~/components/Modal';
import PageHeader from '~/components/PageHeader';
import { SearchInput } from '~/components/Form';
import { RegisterButton } from '~/components/Button';

function Returns() {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [returnsCount, setReturnsCount] = useState(0);
  const [returns, setReturns] = useState([]);

  function getStatusDisplayName(status) {
    switch (status) {
      case 'aguardando-produto':
        return 'Aguardando Produtos';

      case 'aguardando-resposta-cliente':
        return 'Aguardando Resposta do Cliente';

      case 'cliente-notificado':
        return 'Cliente Notificado';

      case 'realizar-entrada-nf':
        return 'Realizar Entrada de NF';
      case 'entrada-nf-realizada':
        return 'Entrada de NF Realizada';

      case 'realizar-estorno':
        return 'Realizar Estorno';

      case 'estorno-realizado':
        return 'Estorno Realizado';

      default:
        return status;
    }
  }

  function getBonusDisplayName(bonus) {
    let bonusDisplayName = '';

    switch (bonus) {
      case 'exchange':
        bonusDisplayName = 'Troca';
        break;

      case 'reversal':
        bonusDisplayName = 'Extorno';
        break;

      case 'exchanged_order':
        bonusDisplayName = 'Pedido Trocado';
        break;

      default:
        bonusDisplayName = bonus;
        break;
    }

    return bonusDisplayName;
  }

  async function loadReturns(q = '') {
    const { data } = await api.get('/return-orders', { params: { q, page } });

    setReturnsCount(data.count);

    setReturns(
      data.rows.map((r) => ({
        ...r,
        status: getStatusDisplayName(r.status),
        bonus: getBonusDisplayName(r.bonus),
      }))
    );
  }

  useEffect(() => {
    loadReturns();
    // eslint-disable-next-line
  }, [page]);

  function handlePagination(current) {
    setPage(current);
  }

  function handleSearch(e) {
    setPage(1);
    if (e.target.value === '') {
      loadReturns();
    }

    if (Math.ceil(Math.log10(e.target.value + 1)) > 3) {
      loadReturns(e.target.value);
    }
  }

  return (
    <Container>
      <PageHeader title="Trocas e Devoluções">
        {/* <FilterButton Icon={FiFilter} background="#333" size={14} /> */}
        <RegisterButton to="/pedidos/troca-devolucao/cadastro" />
      </PageHeader>
      <SearchInput
        type="text"
        name="search"
        onChange={handleSearch}
        placeholder="Buscar pedido do ecommerce"
      />
      <Table>
        <thead>
          <tr>
            <th>Pedido Ecommerce</th>
            <th>Pedido Sisplan</th>
            <th>Status</th>
            <th>Bonificação</th>
            <th>Código de Retorno</th>
            <th>Observação</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {returns.map((r) => (
            <tr key={r.id}>
              <td>{r.order_id}</td>
              <td>{r.sisplan_id}</td>
              <td>{r.status}</td>
              <td>{r.bonus}</td>
              <td>{r.return_code}</td>
              <td>{r.observation}</td>
              <td>
                <TableActions>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        history.push(`/pedidos/troca-devolucao/editar/${r.id}`);
                      }}
                    >
                      <MdModeEdit color={colors.actions.edit} />
                      Editar
                    </button>
                  </div>
                  <div>
                    <Modal>
                      <section>
                        <strong>Informações Adicionais</strong>
                        <span style={{ 'white-space': 'pre-line' }}>
                          {r.additional_data}
                        </span>
                      </section>
                    </Modal>
                  </div>
                </TableActions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        current={page}
        pageSize={limit}
        total={returnsCount}
        onChange={handlePagination}
      />
    </Container>
  );
}

export default Returns;
