import styled from 'styled-components';

export const SelectTitle = styled.span`
  display: block;
  margin: 20px 0 0;
`;

export const TableLine = styled.tr`
  > td {
    background: ${(props) =>
      props.isLate ? 'tomato' : 'lightgreen'} !important;
    color: black !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FilterBox = styled.div``;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: 10px;
`;

export const CardBox = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  width: 280px;
  border-radius: 4px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 10px;
  }
`;

export const CardInfo = styled.div``;
