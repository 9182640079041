import styled from 'styled-components';
import { darken } from 'polished';

export const Button = styled.button`
  display: flex;
  align-items: center;
  text-align: center;

  font-size: 14px;
  font-weight: bold;

  border: 0;
  border-radius: 4px;

  height: 45px;
  padding: 0px 15px;

  color: ${(props) => props.color};
  background: ${(props) => props.background};
  transition: background 0.2s;

  &:hover {
    background: ${(props) => darken(0.05, props.background)};
  }

  > svg {
    margin-right: 5px;
  }
`;
