import React, { useState, useEffect } from 'react';
import { MdModeEdit } from 'react-icons/md';
import Pagination from 'rc-pagination';

import api from '~/services/api';
import history from '~/services/history';

import colors from '~/styles/colors';

import Table from '~/components/Table';
import PageHeader from '~/components/PageHeader';
import TableActions from '~/components/TableActions';
// import { SearchInput } from '~/components/Form';
import { RegisterButton } from '~/components/Button';

import { Container } from './styles';

function Goals() {
  const [goals, setGoals] = useState([]);
  const [goalsCount, setGoalsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(15);

  function getIndicatorDisplayName(selectedIndicator) {
    switch (selectedIndicator) {
      case 'stock_entry':
        return 'Entrada Estoque';
      case 'separation':
        return 'Separação';
      case 'billing':
        return 'Faturamento';
      case 'negotiation':
        return 'Negociação';
      case 'production':
        return 'Produção';
      case 'billing_error':
        return 'Erro de faturamento';
      case 'procon_and_reclame_aqui':
        return 'Procon e Reclame aqui';
      case 'draft':
        return 'Minuta';
      default:
        return '';
    }
  }

  useEffect(() => {
    async function loadJobs() {
      const response = await api.get('/goals', { params: { page, limit } });
      setGoalsCount(response.data.count);
      setGoals(
        response.data.rows.map((goal) => ({
          ...goal,
          typeLabel: getIndicatorDisplayName(goal.indicator),
        }))
      );
    }

    loadJobs();
  }, [page, limit]);

  // function handleSearch() {}

  function handlePagination(current) {
    setPage(current);
  }

  return (
    <Container>
      <PageHeader title="Metas">
        {/* <FilterButton Icon={FiFilter} background="#333" size={14} /> */}
        <RegisterButton to="/metas/cadastro" />
      </PageHeader>
      {/* <SearchInput
        type="text"
        name="search"
        onChange={handleSearch}
        placeholder="Buscar por tipo de meta"
      /> */}
      <Table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Indicador</th>
            <th>Valor</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {goals.map((goal) => (
            <tr key={goal.id}>
              <td>{goal.date}</td>
              <td>{goal.typeLabel}</td>
              <td>{goal.value}</td>
              <td>
                <TableActions>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        history.push(`/metas/editar/${goal.id}`);
                      }}
                    >
                      <MdModeEdit color={colors.actions.edit} />
                      Editar
                    </button>
                  </div>
                </TableActions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        current={page}
        pageSize={limit}
        total={goalsCount}
        onChange={handlePagination}
      />
    </Container>
  );
}

export default Goals;
