import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '~/pages/SignIn';

import Dashboard from '~/pages/Dashboard';
// import Products from '~/pages/Products';
// import ProductFutureBuild from '~/pages/Products/FutureBuild';
import Orders from '~/pages/Orders';
import UsersPrinting from '~/pages/Orders/UsersPrinting';
import OrderReturns from '~/pages/Orders/Returns';
import OrderReturnForm from '~/pages/Orders/Returns/Form';
import OrderReturnEdit from '~/pages/Orders/Returns/Edit';
import OrderBookingCancel from '~/pages/Orders/BookingCancel';
import OrderFetch from '~/pages/Orders/Fetch';
import OrderPrinting from '~/pages/Orders/Printing';
import OrderPrintingRapid from '~/pages/Orders/PrintingRapid';
import OrderPrintingError from '~/pages/Orders/PrintingError';
import OrderSyncingError from '~/pages/Orders/SyncingError';
import OrderInternal from '~/pages/Orders/Internal';
import Goals from '~/pages/Goals';
import GoalForm from '~/pages/Goals/Form';
import GoalEdit from '~/pages/Goals/Edit';
import Jobs from '~/pages/Jobs';
import Users from '~/pages/Users';
import UserForm from '~/pages/Users/Form';
import UserEdit from '~/pages/Users/Edit';
import Settings from '~/pages/Settings';
import Carriers from '~/pages/Carriers';
import CarrierForm from '~/pages/Carriers/Form';
import CarrierEdit from '~/pages/Carriers/Edit';
import EndExpedition from '~/pages/Orders/EndExpedition';
import CompletedPrinting from '~/pages/Orders/CompletedPrinting';
import IncompletePrinting from '~/pages/Orders/IncompletePrinting';
// import ProductMassUpdate from '~/pages/Products/MassUpdate';
// import IncompletedPrinting from '~/pages/Orders/IncompletedPrinting';
import PrintingOne from '~/pages/Orders/PrintingOne';
import SelectedPrinting from '~/pages/Orders/SelectedPrinting';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      {/* <Route path="/produtos" exact component={Products} isPrivate />
      <Route
        path="/produtos/of-futura"
        exact
        component={ProductFutureBuild}
        isPrivate
      /> */}
      {/* <Route
        path="/produtos/atualizacao-massa"
        exact
        component={ProductMassUpdate}
        isPrivate
      /> */}

      <Route path="/pedidos" exact component={Orders} isPrivate />
      <Route
        path="/pedidos/impressao"
        exact
        component={OrderPrinting}
        isPrivate
      />
      <Route
        path="/pedidos/impressao-entrega-rapido"
        exact
        component={OrderPrintingRapid}
        isPrivate
      />
      <Route
        path="/pedidos/impressao-usuario"
        exact
        component={UsersPrinting}
        isPrivate
      />
      <Route
        path="/pedidos/impressao-completos"
        exact
        component={CompletedPrinting}
        isPrivate
      />
      <Route
        path="/pedidos/impressao-incompletos"
        exact
        component={IncompletePrinting}
        isPrivate
      />
      <Route
        path="/pedidos/impressao-individual"
        exact
        component={PrintingOne}
        isPrivate
      />
      <Route
        path="/pedidos/impressao-selecionados"
        exact
        component={SelectedPrinting}
        isPrivate
      />
      <Route
        path="/pedidos/finalizar-expedicao"
        exact
        component={EndExpedition}
        isPrivate
      />
      <Route
        path="/pedidos/erro-impressao"
        exact
        component={OrderPrintingError}
        isPrivate
      />
      <Route
        path="/pedidos/interno"
        exact
        component={OrderInternal}
        isPrivate
      />
      <Route
        path="/pedidos/troca-devolucao"
        exact
        component={OrderReturns}
        isPrivate
      />
      <Route
        path="/pedidos/troca-devolucao/cadastro"
        exact
        component={OrderReturnForm}
        isPrivate
      />
      <Route
        path="/pedidos/troca-devolucao/editar/:id"
        exact
        component={OrderReturnEdit}
        isPrivate
      />
      <Route
        path="/pedidos/cancelar-reserva"
        exact
        component={OrderBookingCancel}
        isPrivate
      />
      <Route path="/pedidos/buscar" exact component={OrderFetch} isPrivate />
      <Route
        path="/pedidos/erro-sincronizacao"
        exact
        component={OrderSyncingError}
        isPrivate
      />
      <Route path="/metas" exact component={Goals} isPrivate />
      <Route path="/metas/cadastro" exact component={GoalForm} isPrivate />
      <Route path="/metas/editar/:id" exact component={GoalEdit} isPrivate />

      <Route path="/transportadoras" exact component={Carriers} isPrivate />
      <Route
        path="/transportadoras/cadastro"
        exact
        component={CarrierForm}
        isPrivate
      />
      <Route
        path="/transportadoras/editar/:sisplanCarrier"
        exact
        component={CarrierEdit}
        isPrivate
      />
      <Route path="/usuarios" exact component={Users} isPrivate />
      <Route path="/usuarios/cadastro" exact component={UserForm} isPrivate />
      <Route path="/usuarios/editar/:id" exact component={UserEdit} isPrivate />
      <Route path="/servicos" exact component={Jobs} isPrivate />
      <Route path="/configuracoes" exact component={Settings} isPrivate />
    </Switch>
  );
}
