import React, { useState, useEffect, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';

import { getOrderStatusLabel } from '~/helpers/ordersHelper';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';

function PrintingError() {
  const [showLoading, setShowLoading] = useState(false);
  const [page] = useState(1);
  const [limit] = useState(10);
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrderCount] = useState(0);

  const loadOrders = useCallback(async () => {
    setShowLoading(true);
    const response = await api.get('/print-orders', {
      params: {
        page,
        limit,
        sortOrder: 'ASC',
        printed: false,
        synced: true,
        has_error: true,
        status: 'processing',
      },
    });

    setOrders(
      response.data.rows.map((order) => {
        return {
          ...order,
          statusLabel: getOrderStatusLabel(order.status),
          synced: order.synced ? 'Sim' : 'Não',
          has_error: order.has_error ? 'Sim' : 'Não',
          created_at: format(
            parseISO(order.created_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
          updated_at: format(
            parseISO(order.updated_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
        };
      })
    );

    setOrderCount(response.data.count);

    setShowLoading(false);
  }, [page, limit]);

  useEffect(() => {
    loadOrders();
  }, [page, limit, loadOrders]);

  return (
    <Container showLoading={showLoading}>
      <PageHeader title={`Pedidos com erro para imprimir: ${ordersCount}`}>
        {/* <IconButton
          Icon={MdSearch}
          action={loadOrders}
          style={{ marginRight: 5 }}
        />
        <input
          type="text"
          name="carrier"
          placeholder="Transportadora"
          value={carrier}
          onChange={(e) => setCarrier(e.target.value)}
          style={{
            paddingLeft: 15,
            marginRight: 10,
            borderRadius: 4,
            border: 1,
            borderStyle: 'solid',
            borderColor: '#dddddd',
          }}
        /> */}

        {/* <IconButton title="IMPRIMIR" Icon={MdPrint} action={handlePrint} /> */}
      </PageHeader>

      <Table>
        <thead>
          <tr>
            <th>ID do Site</th>
            <th>ID do Sisplan</th>
            <th>Status</th>
            <th>Sincronizado</th>
            <th>Erro</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.order_id}</td>
              <td>{order.sisplan_id}</td>
              <td>{order.statusLabel}</td>
              <td>{order.synced}</td>
              <td>{order.has_error}</td>
              <td>{order.created_at}</td>
              <td>{order.updated_at}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default PrintingError;
