import ViacepApi from '~/services/viacep';

export const getOrderStatusLabel = (status) => {
  const statusesLabel = {
    processing: 'Processando',
    new: 'Aguardando Pagamento',
  };

  return statusesLabel[status] || status;
};

export const getOrderErrorType = async (order) => {
  const errorType = [];

  const {
    customer_taxvat: customerTaxvat,
    billing_address: billingAddress,
    extension_attributes: {
      shipping_assignments: [
        {
          shipping: { address: shippingAddress },
        },
      ],
    },
  } = order;

  const taxvat = customerTaxvat.replace(/\D/g, '').trim();
  const billingPostcode = billingAddress.postcode ? billingAddress.postcode.replace(/\D/g, '').trim() : '';
  const shippingPostcode = shippingAddress.postcode ? shippingAddress.postcode.replace(/\D/g, '').trim() : '';
  const billingTelephone = billingAddress.telephone ? billingAddress.telephone.replace(/\D/g, '').trim() : '';
  const shippingTelephone = shippingAddress.telephone ? shippingAddress.telephone.replace(/\D/g, '').trim() : '';

  if (billingAddress.street.length !== 4) {
    errorType.push('Endereço de Cobrança');
  }

  if (shippingAddress.street.length !== 4) {
    errorType.push('Endereço de Entrega');
  }

  if (billingAddress.street[0] && billingAddress.street[0].length > 60) {
    errorType.push('Rua - Endereço de Cobrança');
  }

  if (shippingAddress.street[0] && shippingAddress.street[0].length > 60) {
    errorType.push('Rua - Endereço de Entrega');
  }

  if (billingAddress.street[1] && billingAddress.street[1].length > 10) {
    errorType.push('Número - Endereço de Cobrança');
  }

  if (shippingAddress.street[1] && shippingAddress.street[1].length > 10) {
    errorType.push('Número - Endereço de Entrega');
  }

  if (billingAddress.street[2] && billingAddress.street[2].length > 20) {
    errorType.push('Complemento - Endereço de Cobrança');
  }

  if (shippingAddress.street[2] && shippingAddress.street[2].length > 20) {
    errorType.push('Complemento - Endereço de Entrega');
  }

  if (billingAddress.street[3] && billingAddress.street[3].length > 60) {
    errorType.push('Bairro - Endereço de Cobrança');
  }

  if (shippingAddress.street[3] && shippingAddress.street[3].length > 60) {
    errorType.push('Bairro - Endereço de Entrega');
  }

  if (billingTelephone.length > 11) {
    errorType.push('Telepone - Endereço de Cobrança');
  }

  if (shippingTelephone.length > 11) {
    errorType.push('Telepone - Endereço de Entrega');
  }

  if (!validateTaxvat(taxvat)) {
    errorType.push('CPF');
  }

  if (billingPostcode.length < 8) {
    errorType.push('CEP - Endereço de Cobrança');
  }

  if (shippingPostcode.length < 8) {
    errorType.push('CEP - Endereço de Entrega');
  }

  /* eslint-disable no-empty */
  try {
    let response = await ViacepApi.get(`${billingPostcode}/json`);
    if (response.data.erro) {
      errorType.push('CEP - Endereço de Cobrança');
    }

    if (billingPostcode !== shippingPostcode) {
      response = await ViacepApi.get(`${shippingPostcode}/json`);
      if (response.data.erro) {
        errorType.push('CEP - Endereço de Entrega');
      }
    }
  } catch (error) {}

  return errorType;
};

function validateTaxvat(vat) {
  let taxvat = vat.replace(/\D/g, "");

  let sum = 0;
  let rest;

  if (taxvat.length !== 11) {
    return false;
  }

  if (taxvat === "00000000000") {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(taxvat.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(taxvat.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(taxvat.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(taxvat.substring(10, 11))) {
    return false;
  }

  return true;
};
