import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '~/services/api';

import colors from '~/styles/colors';

import { Input } from '~/components/Form';
import { IconButton } from '~/components/Button';
import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';

import { FormContainer } from './styles';

function PrintingOne() {
  const [loading, setLoading] = useState(false);

  const settings = useSelector((state) => state.user.user.settings);
  const printer = settings.find((setting) => setting.key === 'printer')?.value;

  async function handleSubmit({ sisplan_id }, { reset }) {
    try {
      setLoading(true);
      await api.post(`/print-orders`, {
        orders: [{ sisplan_id: sisplan_id }],
        user: '123456',
        printerQueue: printer,
        reprint: true,
      });

      setLoading(false);
      toast.success('Pedido impresso!');
      reset();
    } catch (error) {
      setLoading(false);
      toast.success('Erro na impressão!');
      console.log(error);
    }
  }

  return (
    <Container showLoading={loading}>
      <PageHeader title="Imprimir Pedido" />
      <FormContainer onSubmit={handleSubmit}>
        <Input
          name="sisplan_id"
          type="text"
          label="Número do Pedido do Sisplan"
          placeholder=""
        />

        <IconButton title="Imprimir Pedido" background={colors.primary} />
      </FormContainer>
    </Container>
  );
}

export default PrintingOne;
