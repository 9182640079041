import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import { MdModeEdit } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';
import history from '~/services/history';

import colors from '~/styles/colors';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';

function Orders() {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [ordersCount, setOrdersCount] = useState(0);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function loadOrders() {
      const response = await api.get('/orders', {
        params: { page, limit },
      });

      setOrdersCount(response.data.count);

      setOrders(
        response.data.rows.map((order) => ({
          ...order,
          synced: order.synced ? 'Sim' : 'Não',
          has_error: order.has_error ? 'Sim' : 'Não',
          created_at: format(parseISO(order.createdAt), 'dd/MM/yyyy hh:mm:ss', {
            locale: pt,
          }),
          updated_at: format(parseISO(order.updatedAt), 'dd/MM/yyyy hh:mm:ss', {
            locale: pt,
          }),
        }))
      );
    }

    loadOrders();
  }, [page, limit]);

  function handlePagination(current) {
    setPage(current);
  }

  return (
    <Container>
      <PageHeader title="Pedidos" />

      <Table>
        <thead>
          <tr>
            <th>ID do Site</th>
            <th>ID do Sisplan</th>
            <th>Status</th>
            <th>Sincronizado</th>
            <th>Erro</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.order_id}</td>
              <td>{order.sisplan_id}</td>
              <td>{order.status}</td>
              <td>{order.synced}</td>
              <td>{order.has_error}</td>
              <td>{order.created_at}</td>
              <td>{order.updated_at}</td>
              <td>
                <TableActions>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        history.push(`/pedidos/editar/${order.id}`);
                      }}
                    >
                      <MdModeEdit color={colors.actions.edit} />
                      Editar
                    </button>
                  </div>
                </TableActions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        current={page}
        pageSize={limit}
        total={ordersCount}
        onChange={handlePagination}
      />
    </Container>
  );
}

export default Orders;
