import React from 'react';

import { Container, Content } from './styles';

function Dashboard() {
  return (
    <Container>
      <Content>
        <iframe
          title="dashboard"
          width="1366"
          height="640"
          src="https://app.powerbi.com/view?r=eyJrIjoiMjUxMTFmNzgtZGRhMS00NmE2LWIzNzYtYTQ4NDYyYzZkOTEzIiwidCI6IjQ2Nzc4ZjM1LTM4YzItNDY5YS1iMzI1LTc2NmRhMTdkYWRkNiJ9"
          frameBorder="0"
          allowFullScreen="true"
        />
      </Content>
    </Container>
  );
}

export default Dashboard;
