import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { Input, Select, Textarea } from '~/components/Form';
import { BackButton, SaveButton } from '~/components/Button';

import { Content } from './styles';

function ReturnForm() {
  const [showLoading, setShowLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState('');
  const [bonus, setBonus] = useState('');

  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    formRef.current.setErrors({});

    try {
      setShowLoading(true);

      const schema = Yup.object().shape({
        order_id: Yup.string().required('Pedido do site é obrigatório'),
        sisplan_id: Yup.string().required('Pedido do sisplan é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      await api.post('/return-orders', {
        ...data,
        status: status.value,
        bonus: bonus.value,
        products,
      });

      setShowLoading(false);

      toast.success('Cadastro realizado com sucesso!');

      history.push('/pedidos/troca-devolucao');
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      setShowLoading(false);
    }
  }

  async function handleBlur() {
    formRef.current.setErrors({});

    try {
      setShowLoading(true);
      const orderId = formRef.current.getFieldValue('order_id');

      if (orderId.length >= 6) {
        const { data } = await api.get(`/orders/${orderId}`);

        const order = data;
        const wcOrder = JSON.parse(order.data);
        setProducts(
          wcOrder.line_items.map((item) => ({
            ...item,
            selected: false,
          }))
        );

        formRef.current.setFieldValue('sisplan_id', data.sisplan_id);
        formRef.current.setFieldValue(
          'customer',
          `${wcOrder.billing.first_name} ${wcOrder.billing.last_name}`
        );
      }
      setShowLoading(false);
    } catch (error) {
      toast.error('Pedido do site não encontrado!');
      setShowLoading(false);
    }
  }

  function handleStatusChange(selectedStatus) {
    setStatus(selectedStatus);
  }

  function handleBonusChange(selectedBonus) {
    setBonus(selectedBonus);
  }

  const statusOptions = [
    {
      value: 'aguardando-produto',
      label: 'Aguardando Produto',
    },
    {
      value: 'aguardando-resposta-cliente',
      label: 'Aguardando Resposta do Cliente',
    },
    {
      value: 'cliente-notificado',
      label: 'Cliente Notificado',
    },
    {
      value: 'realizar-entrada-nf',
      label: 'Realizar Entrada de NF',
    },
    {
      value: 'entrada-nf-realizada',
      label: 'Entrada de NF Realizada',
    },
    {
      value: 'realizar-estorno',
      label: 'Realizar Estorno',
    },
    {
      value: 'estorno-realizado',
      label: 'Estorno Realizado',
    },
  ];

  const bonusOptions = [
    { value: 'exchange', label: 'Troca' },
    { value: 'reversal', label: 'Devolução' },
    { value: 'exchanged_order', label: 'Pedido Trocado' },
  ];

  const motiveOptions = [
    { value: 'size_exchange', label: 'Troca de tamanho' },
    { value: 'exchanged_product', label: 'Produto Trocado' },
    { value: 'modeling', label: 'Modelagem' },
    { value: 'defect', label: 'Defeito' },
    { value: 'reversal', label: 'Devolução' },
  ];

  return (
    <Container showLoading={showLoading}>
      <PageHeader title="Cadastro de Troca e Devolução">
        <BackButton to="/pedidos/troca-devolucao" />
        <SaveButton action={() => formRef.current.submitForm()} />
      </PageHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Input
              type="text"
              label="Pedido do Site"
              name="order_id"
              onBlur={handleBlur}
            />
            <Input
              type="text"
              label="Pedido do Sisplan"
              name="sisplan_id"
              disabled
            />
          </div>
          <div>
            <Select
              type="text"
              label="Status"
              name="status"
              placeholder="Selecione..."
              onChange={handleStatusChange}
              options={statusOptions}
            />
            <Select
              type="text"
              label="Bonificação"
              name="bonus"
              placeholder="Selecione..."
              onChange={handleBonusChange}
              options={bonusOptions}
            />
            <Input type="text" label="Código de Retorno" name="return_code" />
          </div>
          <div>
            <Textarea
              name="observation"
              label="Observação"
              cols="65"
              rows="10"
            />
            <Textarea
              name="additional_data"
              label="Informações Adicionais"
              cols="65"
              rows="10"
            />
          </div>

          <div>
            <Table condensed>
              <thead>
                <tr>
                  <th style={{ width: '50px' }} />
                  <th>SKU</th>
                  <th>Quantidade</th>
                  <th>Preço</th>
                  <th>Motivo</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <Input
                        type="checkbox"
                        name="product"
                        value={product.selected}
                        onChange={() =>
                          setProducts(
                            products.map((p) =>
                              p.id === product.id ? { ...p, selected: true } : p
                            )
                          )
                        }
                      />
                    </td>
                    <td>{product.sku}</td>
                    <td>{product.quantity}</td>
                    <td>{product.total}</td>
                    <td>
                      <Select
                        type="text"
                        name="motive"
                        placeholder="Selecione..."
                        onChange={(selectedMotive) =>
                          setProducts(
                            products.map((p) =>
                              p.id === product.id
                                ? {
                                    ...p,
                                    motive: selectedMotive,
                                  }
                                : p
                            )
                          )
                        }
                        options={motiveOptions}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Form>
      </Content>
    </Container>
  );
}

export default ReturnForm;
