import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdPrint } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';

import { getOrderStatusLabel } from '~/helpers/ordersHelper';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { IconButton } from '~/components/Button';

function PrintingRapid() {
  const [showLoading, setShowLoading] = useState(false);
  const [page] = useState(1);
  const [limit] = useState(10);
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrderCount] = useState(0);
  const [carriersLabels, setCarriersLabels] = useState([]);
  const settings = useSelector((state) => state.user.user.settings);

  const printer = settings.find((setting) => setting.key === 'printer')?.value;
  const initialOrderId = settings.find(
    (setting) => setting.key === 'initial_order_id'
  )?.value;
  const finalOrderId = settings.find(
    (setting) => setting.key === 'final_order_id'
  )?.value;

  // function getCarrierLabel(method) {
  //   switch (method) {
  //     case 'correios_correios41106':
  //     case 'correios_correios40010':
  //       return 'CORREIOS';

  //     case 'datafrete_7':
  //     case 'datafrete_6':
  //       return 'POT SPEED';

  //     case 'datafrete_4':
  //     case 'datafrete_5':
  //       return 'ON TIME';

  //     case 'datafrete_3':
  //       return 'ELOHIM';

  //     case 'datafrete_10':
  //       return 'B2LOG';

  //     case 'datafrete_14':
  //     case 'datafrete_11':
  //     case 'datafrete_9':
  //       return 'RODOE';

  //     case 'datafrete_30':
  //     case 'datafrete_destino-nao-encontrado':
  //       return 'Entrega Rápida';

  //     default:
  //       return '';
  //   }
  // }

  useEffect(() => {
    async function loadCarriersLabels() {
      const { data } = await api.get('/carriers-labels');

      setCarriersLabels(data);
    }

    loadCarriersLabels();
  }, []);

  const getCarrierLabel = useCallback(
    (carrier) => {
      if (carrier === '') {
        return 'TODAS';
      }

      let carrierLabel = carriersLabels.find((carrierLabel) => {
        return carrier.split(',').includes(carrierLabel.carrier);
      });

      if (carrierLabel) {
        carrierLabel = carrierLabel.label;
      }

      return carrierLabel;
    },
    [carriersLabels]
  );

  const loadOrders = useCallback(async () => {
    setShowLoading(true);
    let response = await api.get('/carriers', { params: { excluded: true } });

    const excludedCarriers = response.data.map((carrier) => carrier.carrier);

    response = await api.get('/print-orders', {
      params: {
        page,
        limit,
        sortOrder: 'ASC',
        printed: false,
        synced: true,
        has_error: false,
        status: 'processing',
        carrier: excludedCarriers.join(','),
        initialOrderId,
        finalOrderId,
      },
    });

    setOrders(
      response.data.rows.map((order) => {
        return {
          ...order,
          statusLabel: getOrderStatusLabel(order.status),
          synced: order.synced ? 'Sim' : 'Não',
          has_error: order.has_error ? 'Sim' : 'Não',
          carrierLabel: getCarrierLabel(order.carrier),
          created_at: format(
            parseISO(order.created_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
          updated_at: format(
            parseISO(order.updated_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
        };
      })
    );

    setOrderCount(response.data.count);

    setShowLoading(false);
  }, [page, limit, getCarrierLabel, initialOrderId, finalOrderId]);

  async function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function printOrders() {
    const pOrders = orders.slice(0, 5);

    await api.post('/print-orders', {
      orders: pOrders,
      user: 123456,
      printerQueue: printer,
    });
  }

  async function clearBuffer() {
    await api.get('/clear-buffer');
  }

  async function handlePrint() {
    setShowLoading(true);
    await clearBuffer();
    await printOrders();
    await loadOrders();
    await timeout(2000);
    setShowLoading(false);
  }

  useEffect(() => {
    loadOrders();
  }, [page, limit, loadOrders]);

  return (
    <Container showLoading={showLoading}>
      <PageHeader
        title={`Pedidos de entrega rápida para imprimir: ${ordersCount}`}
      >
        <IconButton title="IMPRIMIR" Icon={MdPrint} action={handlePrint} />
      </PageHeader>

      <Table>
        <thead>
          <tr>
            <th>ID do Site</th>
            <th>ID do Sisplan</th>
            <th>Status</th>
            <th>Sincronizado</th>
            <th>Erro</th>
            <th>Transportadora</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.order_id}</td>
              <td>{order.sisplan_id}</td>
              <td>{order.statusLabel}</td>
              <td>{order.synced}</td>
              <td>{order.has_error}</td>
              <td>{order.carrierLabel}</td>
              <td>{order.created_at}</td>
              <td>{order.updated_at}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default PrintingRapid;
