import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { updateSettingsSuccess, updateSettingsFailure } from './actions';

export function* updateSettings({ payload }) {
  try {
    const { data } = yield call(api.post, 'settings', payload.data);

    const settings = data.settings.map((setting) => ({
      key: setting.key,
      value: setting.value,
    }));

    toast.success('Configurações atualizadas com sucesso!');

    yield put(updateSettingsSuccess(settings));
  } catch (err) {
    toast.error('Erro ao atualizar as configurações, confira seus dados!');
    yield put(updateSettingsFailure());
  }
}

export default all([
  takeLatest('@user/UPDATE_SETTINGS_REQUEST', updateSettings),
]);
