import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import { Input } from '~/components/Form';
import { BackButton, SaveButton } from '~/components/Button';

import { Content } from './styles';

function CarrierForm() {
  const [showLoading, setShowLoading] = useState(false);

  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    formRef.current.setErrors({});

    try {
      setShowLoading(true);

      const schema = Yup.object().shape({
        sisplan_carrier: Yup.string().required('Código do Sisplan é obrigatório'),
        carriers: Yup.string().required('Código da Datafrete é obrigatório'),
        label: Yup.string().required('Nome é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      await api.post('/carriers', data);

      setShowLoading(false);

      toast.success('Cadastro realizado com sucesso!');

      history.push('/transportadoras');
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      setShowLoading(false);
    }
  }

  return (
    <Container showLoading={showLoading}>
      <PageHeader title="Cadastro de Transportadora">
        <BackButton to="/transportadoras" />
        <SaveButton action={() => formRef.current.submitForm()} />
      </PageHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Input
              type="text"
              label="Código do Sisplan"
              name="sisplan_carrier"
            />
            <Input
              type="text"
              label="Código da Datafrete"
              name="carriers"
              flex
            />
            <Input
              type="text"
              label="Nome"
              name="label"
            />
          </div>
          <div>
            <Input
              type="text"
              label="CEP Inicial"
              name="initial_postcode"
            />
            <Input
              type="text"
              label="CEP Final"
              name="final_postcode"
            />
          </div>
        </Form>
      </Content>
    </Container>
  );
}

export default CarrierForm;
