import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdPrint } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/pt-br';

import api from '~/services/api';

import { getOrderStatusLabel } from '~/helpers/ordersHelper';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { IconButton } from '~/components/Button';
import { FilterBox, InputTitle } from './styles';
import 'react-datepicker/dist/react-datepicker.css';

function Printing() {
  const [showLoading, setShowLoading] = useState(false);
  const [page] = useState(1);
  const [limit] = useState(10);
  const [date, setDate] = useState(() =>
    window.localStorage.getItem('select_dates')
      ? new Date(window.localStorage.getItem('select_dates'))
      : null
  );
  const [filterDate, setFilterDate] = useState(() =>
    window.localStorage.getItem('filter_date')
      ? window.localStorage.getItem('filter_date')
      : ''
  );
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrderCount] = useState(0);
  const [carriersLabels, setCarriersLabels] = useState([]);

  const settings = useSelector((state) => state.user.user.settings);

  const printer = settings.find((setting) => setting.key === 'printer')?.value;
  const initialOrderId = settings.find(
    (setting) => setting.key === 'initial_order_id'
  )?.value;
  const finalOrderId = settings.find(
    (setting) => setting.key === 'final_order_id'
  )?.value;

  useEffect(() => {
    async function loadCarriersLabels() {
      const { data } = await api.get('/carriers-labels');

      setCarriersLabels(data);
    }

    loadCarriersLabels();
  }, []);

  const getCarrierLabel = useCallback(
    (carrier) => {
      if (carrier === '') {
        return 'TODAS';
      }

      let carrierLabel = carriersLabels.find((carrierLabel) => {
        return carrier.split(',').includes(carrierLabel.carrier);
      });

      if (carrierLabel) {
        carrierLabel = carrierLabel.label;
      }

      return carrierLabel;
    },
    [carriersLabels]
  );

  const loadOrders = useCallback(async () => {
    setShowLoading(true);

    let response = await api.get('/carriers', { params: { excluded: true } });

    response = await api.get('/print-orders', {
      params: {
        page,
        limit,
        sortOrder: 'ASC',
        printed: false,
        synced: true,
        has_error: false,
        status: 'processing',
        filterDate,
        initialOrderId,
        finalOrderId,
      },
    });

    setOrders(
      response.data.rows.map((order) => {
        return {
          ...order,
          statusLabel: getOrderStatusLabel(order.status),
          synced: order.synced ? 'Sim' : 'Não',
          has_error: order.has_error ? 'Sim' : 'Não',
          carrierLabel: getCarrierLabel(order.carrier),
          created_at: format(
            parseISO(order.created_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
          updated_at: format(
            parseISO(order.updated_at),
            'dd/MM/yyyy HH:mm:ss',
            {
              locale: pt,
            }
          ),
        };
      })
    );

    setOrderCount(response.data.count);

    setShowLoading(false);
  }, [page, limit, getCarrierLabel, filterDate, initialOrderId, finalOrderId]);

  async function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function printOrders() {
    const pOrders = orders.slice(0, 30);

    await api.post('/print-orders', {
      orders: pOrders,
      user: 123456,
      printerQueue: printer,
    });
  }

  async function clearBuffer() {
    await api.get('/clear-buffer');
  }

  async function handlePrint() {
    setShowLoading(true);
    await clearBuffer();
    await printOrders();
    await loadOrders();
    await timeout(2000);
    setShowLoading(false);
  }

  useEffect(() => {
    loadOrders();
  }, [page, limit, loadOrders]);

  return (
    <Container showLoading={showLoading}>
      <PageHeader title={`Pedidos para imprimir: ${ordersCount}`}>
        <IconButton title="IMPRIMIR" Icon={MdPrint} action={handlePrint} />
      </PageHeader>
      <FilterBox>
        <InputTitle>
          <strong>Filtrar por data</strong>
        </InputTitle>
        <DatePicker
          selected={date}
          dateFormat="dd/MM/yyyy"
          onChange={(newDate) => {
            if (newDate !== null && newDate !== '') {
              setDate(newDate);
              window.localStorage.setItem('select_dates', newDate);
            } else {
              setDate(null);
            }
            setFilterDate(moment(newDate).format('YYYY-MM-DD'));
            window.localStorage.setItem(
              'filter_date',
              moment(newDate).format('YYYY-MM-DD')
            );
          }}
        />
      </FilterBox>
      <Table>
        <thead>
          <tr>
            <th>ID do Site</th>
            <th>ID do Sisplan</th>
            <th>Status</th>
            <th>Sincronizado</th>
            <th>Erro</th>
            <th>Transportadora</th>
            <th>Data de Criação</th>
            <th>Ultima Atualização</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.order_id}</td>
              <td>{order.sisplan_id}</td>
              <td>{order.statusLabel}</td>
              <td>{order.synced}</td>
              <td>{order.has_error}</td>
              <td>{order.carrierLabel}</td>
              <td>{order.created_at}</td>
              <td>{order.updated_at}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Printing;
