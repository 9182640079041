import styled from 'styled-components';
import colors from '~/styles/colors';

export const InputTitle = styled.span`
  display: block;
  margin: 20px 0 10px;
`;

export const FilterBox = styled.div`
  input {
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px ${colors.gray};
  }
`;
