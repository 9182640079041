import styled from 'styled-components';

import colors from '~/styles/colors';

export const Content = styled.div`
  /* padding: 20px; */
  margin-top: 35px;
  border-radius: 4px;

  form {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      input {
        max-width: 180px;
      }

      label {
        color: ${colors.darkGray};
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        /* padding: 6px; */
        padding-bottom: 0;
      }

      span {
        height: 10px;
        margin-top: 5px;
        font-size: 16px;
        font-weight: bold;
        color: ${colors.darkGray};
      }
      /* align-items: center; */
    }

    table td {
      background-color: #eee;
    }

    input {
      height: 45px;
      padding: 0 15px;
      border: 1px solid ${colors.lightGray};
      border-radius: 4px;
    }

    select {
      height: 45px;
      min-width: 180px;
      padding: 0 15px;
      border: 1px solid ${colors.lightGray};
      border-radius: 4px;
    }

    /* > section {
      display: flex;
      flex-direction: row;d

      margin-bottom: 15px;
      margin-right: 15px;
    } */
  }
`;
