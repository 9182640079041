import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import colors from '~/styles/colors';

import { Input } from '~/components/Form';
import { IconButton } from '~/components/Button';
import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';

import { FormContainer } from './styles';

function EndExpedition() {
  const formRef = useRef(null);

  async function handleSubmit({ user }, { reset }) {
    try {
      await api.post('/end-expedition', { userCode: user });

      toast.success('Expedição Terminada!');
      reset();
      focusUser();
    } catch (error) {
      toast.error('Erro: Expedição não foi terminada, avise o PROGRAMADOR!');
      console.log(error);
    }
  }

  function focusUser() {
    formRef.current.getFieldRef('user').focus();
  }

  return (
    <Container>
      <PageHeader title="Finalizar Expedição" />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="user"
          type="text"
          label="Código do Usuário"
          placeholder=""
          autoFocus
        />

        <IconButton title="Finalizar Expedição" background={colors.primary} />
      </FormContainer>
    </Container>
  );
}

export default EndExpedition;
