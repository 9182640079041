import React, { useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { toast } from 'react-toastify';

import api from '~/services/api';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import Table from '~/components/Table';
import { IconButton, SaveButton } from '~/components/Button';

import { Content } from './styles';

function Internal() {
  const [showLoading, setShowLoading] = useState(false);
  const [products, setProducts] = useState([
    {
      reference: '',
      name: '',
      color: '',
      colors: [],
      size: '',
      sizes: [],
      quantity: 0,
      price: 0,
    },
  ]);

  const [clientId, setClientId] = useState('');
  const [clientName, setClientName] = useState('');
  const [agentId, setAgentId] = useState('');
  const [agentName, setAgentName] = useState('');
  const [lastOrderId, setLastOrderId] = useState('');

  async function handleChangeReference(e, index) {
    const reference = e.target.value;

    setProducts(
      products.map((product, i) =>
        i === index ? { ...product, reference } : product
      )
    );
  }

  function handleChangeQuantity(e, index) {
    const quantity = e.target.value;

    setProducts(
      products.map((product, i) =>
        i === index ? { ...product, quantity } : product
      )
    );
  }

  function handleAddProduct() {
    setProducts([
      ...products,
      {
        reference: '',
        name: '',
        color: '',
        colors: [],
        size: '',
        sizes: [],
        quantity: 0,
        price: 0,
      },
    ]);
  }

  function handleRemoveProduct(e, index) {
    e.preventDefault();

    setProducts(
      products.filter((product, i) => (i !== index ? product : null))
    );
  }

  async function handleSubmit() {
    try {
      setShowLoading(true);
      const { data } = await api.post('/sisplan-orders', {
        clientId,
        agentId,
        products,
      });

      setLastOrderId(data.numero);

      const productIds = [
        ...new Set(products.map((product) => product.reference)),
      ];

      await api.get('/update-wc-products-stock-price', {
        params: { productIds: productIds.join(',') },
      });

      setClientId('');
      setClientName('');
      setAgentId('');
      setAgentName('');
      setProducts([
        {
          reference: '',
          name: '',
          color: '',
          colors: [],
          size: '',
          sizes: [],
          quantity: 0,
          price: 0,
        },
      ]);

      setShowLoading(false);
    } catch (error) {
      toast.error('Não foi possivel criar o pedido!');
      setShowLoading(false);
    }
  }

  function getData(key) {
    const cached = window.sessionStorage.getItem(key);

    return JSON.parse(cached);
  }

  function setData(key, data) {
    window.sessionStorage.setItem(key, JSON.stringify(data));

    return data;
  }

  async function handleReferenceBlur(index) {
    try {
      setShowLoading(true);

      const product = products.filter((p, i) => (i === index ? p : null));
      const { reference } = product[0];

      if (reference.length === 4) {
        let cachedSisplanProduct = getData(`sisplanProduct.${reference}`);

        if (!cachedSisplanProduct) {
          const { data } = await api.get(`/sisplan-products/${reference}`);

          cachedSisplanProduct = setData(`sisplanProduct.${reference}`, data);
        }

        const { colors, sizes, name, price } = cachedSisplanProduct;

        setProducts(
          products.map((p, i) =>
            i === index
              ? {
                  ...p,
                  reference,
                  disabled: true,
                  name,
                  color: colors[0].value,
                  colors,
                  size: sizes[0].value,
                  sizes,
                  quantity: 0,
                  price,
                }
              : p
          )
        );
      }

      setShowLoading(false);
    } catch (error) {
      toast.error('Referência não encontrada!');
      setShowLoading(false);
    }
  }

  async function handleClientIdBlur() {
    try {
      setShowLoading(true);
      if (clientId.length === 5) {
        const { data } = await api.get(`/sisplan-clients/${clientId}`);

        setClientName(data.name);
        setLastOrderId('');
      }

      setShowLoading(false);
    } catch (error) {
      toast.error('Cliente não encontrado!');
      setShowLoading(false);
    }
  }

  async function handleAgentIdBlur() {
    try {
      setShowLoading(true);
      if (agentId.length === 5) {
        const { data } = await api.get(`/sisplan-agents/${agentId}`);

        setAgentName(data.name);
      }

      setShowLoading(false);
    } catch (error) {
      toast.error('Representante não encontrado!');
      setShowLoading(false);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;

    switch (name) {
      case 'clientId':
        setClientId(value);
        break;

      case 'agentId':
        setAgentId(value);
        break;

      default:
        break;
    }
  }

  function handleChangeColor(e, index) {
    const { value } = e.target;

    setProducts(
      products.map((product, i) =>
        i === index ? { ...product, color: value } : product
      )
    );
  }

  function handleChangeSize(e, index) {
    const { value } = e.target;

    setProducts(
      products.map((product, i) =>
        i === index ? { ...product, size: value } : product
      )
    );
  }

  return (
    <Container showLoading={showLoading}>
      <PageHeader title={`Pedido Interno - Ultimo Pedido: ${lastOrderId}`}>
        <SaveButton action={() => handleSubmit()} />
      </PageHeader>

      <Content>
        {/* eslint-disable jsx-a11y/label-has-associated-control */}
        <form>
          <div>
            <label htmlFor="clientId">Cliente</label>
            <input
              type="text"
              name="clientId"
              value={clientId}
              onChange={handleChange}
              onBlur={handleClientIdBlur}
            />
            <span>{clientName}</span>
          </div>

          <div>
            <label htmlFor="agentId">Representante</label>
            <input
              type="text"
              name="agentId"
              value={agentId}
              onChange={handleChange}
              onBlur={handleAgentIdBlur}
            />
            <span>{agentName}</span>
          </div>

          <Table condensed>
            <thead>
              <tr>
                <th style={{ width: '11%' }}>Referência</th>
                <th style={{ width: '20%' }}>Nome</th>
                <th style={{ width: '15%' }}>Cor</th>
                <th style={{ width: '15%' }}>Tamanho</th>
                <th style={{ width: '10%' }}>Quantidade</th>
                <th style={{ width: '10%' }}>Preço</th>
                <th style={{ width: '5%' }}>Remover</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={String(index)}>
                  <td>
                    <input
                      name={`products.${index}.reference`}
                      value={product.reference}
                      onChange={(e) => handleChangeReference(e, index)}
                      onBlur={() => handleReferenceBlur(index)}
                      disabled={product.disabled}
                    />
                  </td>
                  <td>{product.name}</td>
                  <td>
                    <select
                      name={`products.${index}.color`}
                      value={product.color}
                      onChange={(e) => handleChangeColor(e, index)}
                    >
                      {product.colors.map((color, i) => (
                        <option key={String(i)} value={color.value}>
                          {color.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name={`products.${index}.size`}
                      value={product.size}
                      onChange={(e) => handleChangeSize(e, index)}
                    >
                      {product.sizes.map((size, i) => (
                        <option key={String(i)} value={size.value}>
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="number"
                      name={`products.${index}.quantity`}
                      value={product.quantity}
                      onChange={(e) => handleChangeQuantity(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      name={`products.${index}.price`}
                      value={product.price}
                      readOnly
                    />
                  </td>
                  <td>
                    <IconButton
                      Icon={MdRemove}
                      action={(e) => {
                        handleRemoveProduct(e, index);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </form>
        <div>
          <IconButton
            title="Adicionar Produto"
            Icon={MdAdd}
            action={(e) => {
              handleAddProduct(e);
            }}
          />
        </div>
      </Content>
    </Container>
  );
}
export default Internal;
