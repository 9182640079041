import React, { useState, useEffect } from 'react';
import { MdModeEdit } from 'react-icons/md';
import Pagination from 'rc-pagination';

import api from '~/services/api';
import history from '~/services/history';

import colors from '~/styles/colors';

import PageHeader from '~/components/PageHeader';
import TableActions from '~/components/TableActions';
import Table from '~/components/Table';
import { RegisterButton } from '~/components/Button';

import { Container } from './styles';

function Users() {
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(15);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get('/users', { params: { page, limit } });
      setUsersCount(response.data.count);
      setUsers(response.data.rows);
    }
    loadUsers();
  }, [page, limit]);

  function handlePagination(current) {
    setPage(current);
  }

  return (
    <Container>
      <PageHeader title="Usuários">
        <RegisterButton to="/usuarios/cadastro" />
      </PageHeader>
      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Código</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.code}</td>
              <td>
                <TableActions>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        history.push(`/usuarios/editar/${user.id}`);
                      }}
                    >
                      <MdModeEdit color={colors.actions.edit} />
                      Editar
                    </button>
                  </div>
                </TableActions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        current={page}
        pageSize={limit}
        total={usersCount}
        onChange={handlePagination}
      />
    </Container>
  );
}

export default Users;
