import styled from 'styled-components';
import colors from '~/styles/colors';

export const Content = styled.div`
  padding: 20px;
  margin-top: 35px;
  border-radius: 4px;
  background: ${colors.white};

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      display: flex;
      padding: 10px;
      width: 50%;
      min-width: 250px;
    }
  }
`;
