import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  padding: 2rem;
`;

export const FormContainer = styled(Form)`
  display: flex;
  align-items: flex-end;
  /* flex-direction: column; */
  /* max-width: 200px; */
  button {
    margin-bottom: 10px;
  }
`;
