import React from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import colors from '~/styles/colors';

import { Input } from '~/components/Form';
import { IconButton } from '~/components/Button';
import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';

import { FormContainer } from './styles';

function BookingCancel() {
  async function handleSubmit({ numero }, { reset }) {
    try {
      if (window.confirm('Deseja cancelar reserva?')) {
        await api.delete(`/order-booking-cancel/${numero}`);

        toast.success('Reserva cancelada!');
        reset();
      }
    } catch (error) {
      toast.error('Erro: Reserva não foi cancelada!');
      console.log(error);
    }
  }

  return (
    <Container>
      <PageHeader title="Cancelar Reserva" />
      <FormContainer onSubmit={handleSubmit}>
        <Input
          name="numero"
          type="text"
          label="Número do Pedido"
          placeholder=""
        />

        <IconButton title="Cancelar Reserva" background={colors.primary} />
      </FormContainer>
    </Container>
  );
}

export default BookingCancel;
