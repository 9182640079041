export function updateSettingsRequest(data) {
  return {
    type: '@user/UPDATE_SETTINGS_REQUEST',
    payload: { data },
  };
}

export function updateSettingsSuccess(settings) {
  return {
    type: '@user/UPDATE_SETTINGS_SUCCESS',
    payload: { settings },
  };
}

export function updateSettingsFailure() {
  return {
    type: '@user/UPDATE_SETTINGS_FAILURE',
  };
}
